import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgTransitionCover from "../../assets/images/journey/transition/transition-bg.png"

const CruisePage = () => (
  <Layout lang="ar">
    <SEO title="Transition Phase" lang="ar" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #880C0C"}}>
        <img src={imgTransitionCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                  مرحلة التنقل                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  تبدأ هذه المرحلة بمناورات الانتقال من مدار الالتقاط إلى المدار العلمي المناسب حتى يتمكن المسبار من أداء مهامه العلمية الأساسية. 
                  </p>
                  <p>
                  يتخذ مدار الالتقاط شكلاً بيضاوياً وتصل مدة الدورة الواحدة حول الكوكب فيه إلى 40 ساعة، وفيه سيكون "مسبار الأمل" على ارتفاع 1000 كيلومتر فوق سطح المريخ وعلى بعد 49,380 كيلومتر منه.
                  </p>
                  <p>
                  سيقوم "مسبار الأمل" بالتقاط الصورة الأولى للمريخ وإرسالها إلى مركز العمليات الأرضية. ليتم بعدها جدولة الاتصال اليومي المحطة الأرضية حتى يتمكن فريق المشروع من إجراء عمليات تحميل سلسلة الأوامر واستلام بيانات عمليات القياس عن بُعد.
                  </p>
                  <p>
                  سيتخذ "مسبار الأمل" مداراً بيضاوياً حول المريخ على ارتفاع يتراوح بين 20,000 إلى 43,000 كيلومتر، يستغرق فيه المسبار 55 ساعة لإتمام دورة كاملة حول المريخ. ويُعد المدار الذي اختاره فريق الإمارات لاستكشاف المريخ مبتكراً للغاية وفريداً من نوعه وسيسمح لمسبار الأمل بإمداد المجتمع العليم بأول صورة على الإطلاق لديناميكيات الغلاف الجوي لكوكب المريخ وطقسه على مدار ساعات اليوم وطوال أيام الأسبوع.
                  </p>
                  <p>
                  تقتصر عدد مرات اتصال "مسبار الأمل" مع المحطة الأرضية على مرتين فقط في الأسبوع وتتراوح مدة الاتصال الواحد بين 6 إلى 8 ساعات، ومن المخطط أن ينقل المسبار خلال هذه الفترة مجموعة كبيرة من البيانات العلمية عن الغلاف الجوي للمريخ وديناميكياته وسيصل حجم هذه البيانات بنهاية المهمة إلى أكثر من 1 تيرابايت من البيانات العلمية في صورتها الأولية.  
                  </p>
                </div>
            </div>  
                   
          </div>  
      </section>
    </main>
  </Layout>
)

export default CruisePage
